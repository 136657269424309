import "./App.css";
import axios from "axios";

// Components
import { React, useEffect, useState, useRef } from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import io from "socket.io-client";
import Header from "components/Header/Header";
import LiveUsers from "components/LiveUsers/LiveUsers";

import LandingPage from "./pages/LandingPage/LandingPage";
import ChooseVote from "./pages/Vote/ChooseVote";
import VoteRes from "./pages/Vote/VoteRes";
import Vote from "./pages/Vote/Vote";
import Live from "./pages/Live/Live";
import About from "./pages/About/About";

function App() {
  const cursorDot = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const posX = e.clientX;
      const posY = e.clientY;

      if (cursorDot.current) {
        cursorDot.current.style.left = `${posX}px`;
        cursorDot.current.style.top = `${posY}px`;
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousemove", handleMouseMove);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const socket = io.connect(process.env.REACT_APP_API_URL, {
    withCredentials: true,
  });

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api",
  });

  return (
    <>
      <div className="cursor-dot" ref={cursorDot}></div>
      <div className="clout-bg"></div>
      <LiveUsers socket={socket} />
      <div className="dark">
        <Toaster
          toastOptions={{
            duration: 3000,
          }}
        />

        <Header />
        <Routes>
          <Route path="*" element={<Navigate to="/" replace={true} />} />
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/live"
            element={<Live socket={socket} axios={axiosInstance} />}
          />
          {/* <Route path="/about" element={<About />} /> */}

          <Route path="/vote">
            <Route path="" element={<ChooseVote axios={axiosInstance} />} />

            <Route path=":voteId" element={<Vote axios={axiosInstance} />} />

            <Route
              path=":voteId/votes"
              element={<VoteRes axios={axiosInstance} />}
            />
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
