import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";

// Components
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";

// Assets
import TetartoLogo from "assets/img/branding/TetartoLogo.png";

import VolumeIcon from "assets/img/icons/volume.png";
import MuteIcon from "assets/img/icons/mute.png";
import VoteIcon from "assets/img/icons/vote.png";
import LiveIcon from "assets/img/icons/live.png";

import Video1 from "assets/video/video1.mp4";
import InstagramIcon from "assets/img/icons/instagram.png";
import UpdatedIcon from "assets/img/icons/updated.png";

export default function LandingPage() {
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const toggleMute = (event) => {
    event.preventDefault(); // Prevent default behavior on iOS
    setIsMuted(!isMuted);
    videoRef.current.muted = !videoRef.current.muted;
  };

  return (
    <>
      <center className="my-10">
        <div className="w-full max-w-[1250px] flex flex-col gap-1 items-center px-4">
          <img src={TetartoLogo} className="undraggable mb-4 w-24 md:w-36" />
          <h1 className="text-3xl md:text-4xl font-bold flex items-center gap-2">
            <span className="magic">4ο ΓΕΛ Χαϊδαρίου</span>{" "}
            <span className="font-normal flex items-center">
              (2.1.2) <img src={UpdatedIcon} width={40} />
            </span>
          </h1>
          <span className="text-lg md:text-2xl font-semibold flex items-center gap-1">
            👋 Καλώς Ορίσατε
          </span>
          <Separator
            className="my-4 opacity-50"
            style={{ backgroundColor: "var(--primary-color)" }}
          />
          <h1 className="text-xl md:text-3xl font-bold">
            Ενημερωθείτε, Ψηφίστε, Υποστηρίξτε!
          </h1>
          <p>
            Από ζωντανά αποτελέσματα μέχρι ψηφοφορίες για τον καλύτερο παίκτη,
            εδώ θα βρείτε τα πάντα για τους αγώνες μας. Μην χάσετε λεπτό από τη
            δράση!
          </p>

          <div className="grid grid-cols-2 gap-4 w-4-6 md:w-5/6 my-4">
            <Link to="/vote">
              <Button className="w-full flex gap-2" variant="outline">
                <img src={VoteIcon} className="invert" width={18} />
                <span className="mt-0.5">Ψήφισε ΤΏΡΑ!</span>
              </Button>
            </Link>
            <Link to="/live">
              <Button
                className="w-full flex gap-2 animate-bounce"
                variant="destructive"
              >
                <img src={LiveIcon} className="invert" width={18} />
                <span className="mt-0.5">Ζωντανός Αγώνας</span>
              </Button>
            </Link>
          </div>

          <div className="flex flex-col items-center">
            <video
              ref={videoRef}
              src={Video1}
              autoPlay
              loop
              muted={isMuted}
              playsInline // Prevent iOS default video player
              className="w-5/6 md:w-1/3 h-auto rounded-lg border-2"
              style={{ borderColor: "var(--primary-color)" }}
              onTouchStart={(e) => e.preventDefault()} // Prevent default behavior on iOS
            />
            <Button
              size="icon"
              variant="outline"
              onClick={toggleMute}
              onTouchStart={(e) => e.preventDefault()} // Prevent default behavior on iOS
              className="mt-2"
            >
              <img
                src={isMuted ? MuteIcon : VolumeIcon}
                width={20}
                className="invert"
              />
            </Button>
          </div>

          <a
            href="https://www.instagram.com/4ogelchaidariou/"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button variant="outline" className="mt-4 flex items-center gap-2">
              <img src={InstagramIcon} width={20} />{" "}
              <span className="mt-0.5">Βρείτε μας στο Instagram</span>
            </Button>
          </a>
        </div>
      </center>
    </>
  );
}
