import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

// Components
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "components/ui/navigation-menu";
import { Button } from "components/ui/button";
import { Drawer, DrawerTrigger, DrawerContent } from "components/ui/drawer";

// Assets
import TetartoLogo from "assets/img/branding/TetartoLogo.png";
import InstagramLogo from "assets/img/icons/instagram.png";
import WhiteLogo from "assets/img/branding/logo_purple_bg.png";

import MenuIcon from "assets/img/icons/menu.png";

export default function Header({ account }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="header flex flex-col md:flex-row justify-between items-center p-4">
      <div className="flex flex-row items-center gap-4 md:gap-10 w-full justify-between">
        <div className="flex items-center gap-2">
          <Link to="/">
            <img src={TetartoLogo} width={50} className="undraggable" />
          </Link>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <div className="flex flex-col items-start">
                <strong>4ο ΓΕΛ Χαϊδαρίου</strong>
                <span className="text-xs flex items-center gap-0.5">
                  Created with ❣️ by{" "}
                  <div className="flex items-center gap-0.5">
                    <img src={WhiteLogo} width={17} className="rounded-full" />
                    <strong className="magic mt-0.5">@nikos.thc</strong>
                  </div>
                </span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <Link to="/">
                <DropdownMenuItem className="font-bold cursor-pointer flex gap-1 items-center">
                  <span>🏠</span>
                  <span>Αρχική</span>
                </DropdownMenuItem>
              </Link>

              <a
                href="https://www.instagram.com/nikos.thc/"
                target="_blank"
                style={{ textDecoration: "none" }}
                onClick={handleLinkClick}
              >
                <DropdownMenuItem className="font-bold cursor-pointer flex gap-2 items-center">
                  <img src={InstagramLogo} width={17} className="undraggable" />
                  <span className="mt-1">Creator's Instagram</span>
                </DropdownMenuItem>
              </a>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="md:hidden">
          <Drawer open={isOpen} onOpenChange={setIsOpen}>
            <DrawerTrigger asChild>
              <Button variant="outline" onClick={() => setIsOpen(true)}>
                <img src={MenuIcon} width={20} className="invert" />
              </Button>
            </DrawerTrigger>
            <DrawerContent className="flex flex-col items-center">
              <NavigationMenu>
                <NavigationMenuList className="flex flex-col items-center gap-4">
                  <NavigationMenuItem className="mt-4">
                    <Link to="/" onClick={handleLinkClick}>
                      <NavigationMenuLink className="text-center w-full">
                        Αρχική
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  {/*                   <NavigationMenuItem className="mb-0">
                    <Link to="/about" onClick={handleLinkClick}>
                      <NavigationMenuLink className="text-center w-full">
                        Πληροφορίες
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem> */}
                  <NavigationMenuItem className="mb-0">
                    <Link to="/vote" onClick={handleLinkClick}>
                      <NavigationMenuLink className="text-center w-full">
                        Ψήφοι
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem className="mb-1">
                    <Link to="/live" onClick={handleLinkClick}>
                      <NavigationMenuLink className="text-center w-full flex gap-1">
                        🔴 Ζωντανός Αγώνας
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem className="mb-4">
                    <a
                      href="https://www.instagram.com/4ogelchaidariou/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      onClick={handleLinkClick}
                    >
                      <NavigationMenuLink className="text-center w-full">
                        <img
                          src={InstagramLogo}
                          width={20}
                          className="undraggable"
                        />
                      </NavigationMenuLink>
                    </a>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
            </DrawerContent>
          </Drawer>
        </div>

        <NavigationMenu className="hidden md:flex">
          <NavigationMenuList className="flex flex-col md:flex-row items-center gap-2">
            <NavigationMenuItem>
              <Link to="/">
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                  Αρχική
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
            {/*             <NavigationMenuItem>
              <Link to="/about">
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                  Πληροφορίες
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem> */}
            <NavigationMenuItem>
              <Link to="/vote">
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                  Ψήφοι
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link to="/live">
                <NavigationMenuLink
                  className={`${navigationMenuTriggerStyle()} flex gap-1`}
                >
                  🔴 Ζωντανός Αγώνας
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <a
                href="https://www.instagram.com/4ogelchaidariou/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                  <img src={InstagramLogo} width={20} className="undraggable" />
                </NavigationMenuLink>
              </a>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </div>
  );
}
