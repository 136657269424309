import { React, useState, useEffect } from "react";
import { Button } from "components/ui/button";
import { Link } from "react-router-dom";

// Assets
import TetartoLogo from "assets/img/branding/TetartoLogo.png";

import FootballIcon from "assets/img/icons/football.png";
import BasketballIcon from "assets/img/icons/basketball-ball.png";
import VolleyballIcon from "assets/img/icons/volley-ball.png";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";

export default function ChooseVote({ axios }) {
  const [voteData, setVoteData] = useState({});

  useEffect(() => {
    axios.get("votes").then((res) => {
      setVoteData(res.data);
    });
  }, []);

  return (
    <center className="my-10">
      <div className="w-full max-w-[1250px] flex flex-col gap-1 items-center px-4">
        <img src={TetartoLogo} className="undraggable mb-4 w-24 md:w-36" />
        <h1 className="text-3xl md:text-4xl font-bold">
          <span className="magic">Διάλεξε Άθλημα</span> - ( Vote )
        </h1>
        <span className="text-lg md:text-2xl font-semibold flex items-center gap-1">
          Total Votes: <span className="magic">{voteData.totalVotes}</span>
        </span>

        <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4 w-full md:w-2/3">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button className="w-full p-8 flex gap-2">
                <img src={FootballIcon} width={32} />
                <div className="flex flex-col items-start">
                  <span className="text-lg font-bold">
                    Ποδόσφαιρο (Αντρικό)
                  </span>
                  <p className="text-xs">Ψήφισε τον αγαπημένο σου παίκτη!</p>
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {voteData.votes?.some((vote) => vote.team === "footballm") ? (
                <DropdownMenuItem className="font-bold" disabled>
                  ❣️ Ψήφισε
                </DropdownMenuItem>
              ) : (
                <DropdownMenuItem className="font-bold">
                  <Link to="/vote/footballm">❣️ Ψήφισε</Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem className="font-bold">
                <Link to="/vote/footballm/votes">📊 Αποτελέσματα!</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button className="w-full p-8 flex gap-2">
                <img src={FootballIcon} width={32} />
                <div className="flex flex-col items-start">
                  <span className="text-lg font-bold">
                    Ποδόσφαιρο (Γυναικείο)
                  </span>
                  <p className="text-xs">Ψήφισε τον αγαπημένο σου παίκτη!</p>
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {voteData.votes?.some((vote) => vote.team === "footballf") ? (
                <DropdownMenuItem className="font-bold" disabled>
                  ❣️ Ψήφισε
                </DropdownMenuItem>
              ) : (
                <DropdownMenuItem className="font-bold">
                  <Link to="/vote/footballf">❣️ Ψήφισε</Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem className="font-bold">
                <Link to="/vote/footballf/votes">📊 Αποτελέσματα!</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button className="w-full p-8 flex gap-2">
                <img src={BasketballIcon} width={32} />
                <div className="flex flex-col items-start">
                  <span className="text-lg font-bold">Μπάσκετ (Αντρικό)</span>
                  <p className="text-xs">Ψήφισε τον αγαπημένο σου παίκτη!</p>
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {voteData.votes?.some((vote) => vote.team === "basketballm") ? (
                <DropdownMenuItem className="font-bold" disabled>
                  ❣️ Ψήφισε
                </DropdownMenuItem>
              ) : (
                <DropdownMenuItem className="font-bold">
                  <Link to="/vote/basketballm">❣️ Ψήφισε</Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem className="font-bold">
                <Link to="/vote/basketballm/votes">📊 Αποτελέσματα!</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            className="w-full p-8 flex gap-2 row-start-3 row-end-5"
            disabled
          >
            <img src={VolleyballIcon} width={32} />
            <div className="flex flex-col items-start">
              <span className="text-lg font-bold">Βόλεϊ (Αντρικό)</span>
              <p className="text-xs">Ψήφισε τον αγαπημένο σου παίκτη!</p>
            </div>
          </Button>
          <Button className="w-full p-8 flex gap-2" disabled>
            <img src={VolleyballIcon} width={32} />
            <div className="flex flex-col items-start">
              <span className="text-lg font-bold">Βόλεϊ (Γυναικείο)</span>
              <p className="text-xs">Ψήφισε τον αγαπημένο σου παίκτη!</p>
            </div>
          </Button>
        </div>

        <Link to="/" className="text-yellow-500 hover:underline mt-4">
          <Button variant="outline">🔙 Πίσω στην αρχική</Button>
        </Link>
      </div>
    </center>
  );
}
