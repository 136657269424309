import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";

import toast from "react-hot-toast";

import TetartoLogo from "assets/img/branding/TetartoLogo.png";

export default function Vote({ axios }) {
  const { voteId } = useParams();
  const [team, setTeam] = useState({ label: voteId, players: [] });
  const [selectedPlayer, setSelectedPlayer] = useState(0);
  const [selectedPlayerLabel, setSelectedPlayerLabel] = useState("");

  useEffect(() => {
    axios.get(`players/${voteId}`).then((res) => {
      setTeam(res.data);
    });
  }, []);

  const handleVote = (e) => {
    e.preventDefault();
    const promise = axios.post(`vote/${voteId}/${selectedPlayer}`);
    toast
      .promise(promise, {
        loading: "Submitting vote...",
        success: "Vote submitted successfully!",
        error: "There was an error while voting. Please try again.",
      })
      .then(() => {
        setTimeout(() => {
          window.location = `/vote/${voteId}/votes`;
        }, 1250);
      });
  };

  const onRadioChange = (value) => {
    setSelectedPlayer(value);
    setSelectedPlayerLabel(
      team.players.find((_, index) => index === value).name
    );
  };

  return (
    <center className="my-10">
      <div className="w-full max-w-[1250px] flex flex-col gap-1 items-center px-4">
        <img src={TetartoLogo} className="undraggable mb-4 w-24 md:w-36" />
        <h1 className="text-3xl md:text-4xl font-bold">
          <span className="magic">Vote</span> - ( {team.label} )
        </h1>
        <span className="text-lg md:text-2xl font-semibold flex items-center gap-1">
          Ψήφισε τον αγαπημένο σου παίκτη!
        </span>

        <Link to="/vote" className="text-yellow-500 hover:underline mt-4">
          <Button variant="outline">🔙 Πίσω στην επιλογή</Button>
        </Link>

        <form className="my-4 w-full" onSubmit={handleVote}>
          <RadioGroup
            className="w-11/12 md:w-2/3 grid gap-2 items-center grid-cols-1 md:grid-cols-2 justify-center"
            onValueChange={onRadioChange}
          >
            {team.players.map((player, index) => (
              <div
                key={index}
                className="flex items-center space-x-2 bg-yellow-800 rounded p-4"
              >
                <RadioGroupItem value={index} id={player.name} />
                <Label
                  htmlFor={player.name}
                  className="flex items-center gap-2"
                >
                  <Avatar className="w-8 h-8">
                    <AvatarImage src={player.pfp} />
                    <AvatarFallback>{player.name[0]}</AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col items-start mt-0.5">
                    <span>{player.name}</span>
                    <span className="text-sm">
                      Votes:{" "}
                      <span className="text-yellow-200">{player.votes}</span>
                    </span>
                  </div>
                </Label>
              </div>
            ))}
          </RadioGroup>
          <Button
            variant="outline"
            type="submit"
            className="flex flex-col items-center py-8 mt-4 w-full md:w-1/2"
          >
            <span className="text-yellow-500 text-lg">Ψηφίστε Τώρα!</span>
            <span className="text-gray-400 text-xs">
              {selectedPlayerLabel
                ? `Πρόκειται να ψηφίσετε: ${selectedPlayerLabel}`
                : "Δεν επιλέχθηκε ψήφος"}
            </span>
          </Button>
        </form>
      </div>
    </center>
  );
}
