import React, { useEffect, useState } from "react";
import "./LiveUsers.css";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "components/ui/hover-card";

import GroupIcon from "assets/img/icons/team.png";

export default function LiveUsers({ socket }) {
  const [liveUsers, setLiveUsers] = useState(0);
  useEffect(() => {
    console.log("something changed");
    socket.on("liveUsers", (data) => {
      setLiveUsers(data);
      console.log(data);
    });

    return () => {
      socket.off("liveUsers");
    };
  }, [socket]);

  return (
    <HoverCard>
      <HoverCardTrigger className="live-users-container">
        <div className="live-users">
          <span role="img" aria-label="users" className="live-users-emoji">
            <img
              src={GroupIcon}
              className="undraggable"
              alt="users"
              width={40}
            />
          </span>
          <div className="live-users-count-container">
            <span className="live-users-count text-blue-950">{liveUsers}</span>
          </div>
        </div>
      </HoverCardTrigger>
      <HoverCardContent className="small-hover-card-content">
        Live users:
      </HoverCardContent>
    </HoverCard>
  );
}
