import { React, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "components/ui/button";

import TetartoLogo from "assets/img/branding/TetartoLogo.png";

import ChatContainer from "./ChatContainer";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";

// Assets
import RedCardIcon from "assets/img/icons/red-card.png";
import YellowCardIcon from "assets/img/icons/yellow-card.png";
import SwitchIcon from "assets/img/icons/switch.png";

export default function Live({ axios, socket }) {
  const [liveMatch, setLiveMatch] = useState(false);
  const [score1, setScore1] = useState(0);
  const [score2, setScore2] = useState(0);
  const [enemyTeam, setEnemyTeam] = useState("(No Name)");
  const [goals, setGoals] = useState([]);
  const [matchFinished, setMatchFinished] = useState(false);
  const [matchTime, setMatchTime] = useState(0);
  const [isHalftime, setIsHalftime] = useState(false);
  const [currentTab, setCurrentTab] = useState(false);

  useEffect(() => {
    axios
      .get("/live")
      .then((res) => {
        if (res.data.started) {
          const data = res.data;
          setLiveMatch(data.started);
          setScore1(data.score1 || 0);
          setScore2(data.score2 || 0);
          setGoals(data.goals || []);
          setEnemyTeam(data.enemyTeam || "(No Name)");
          //setMatchStartTime(data.matchStartTime || null);
          setIsHalftime(data.isHalftime || false);
          setMatchTime(data.matchTime || 0);
        }
      })
      .catch((error) => {
        console.error("Error fetching live match data:", error);
      });
  }, []);

  useEffect(() => {
    socket.on("add-goal-1", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        {
          team: 1,
          scorer: data.scorer,
          mention: data.mention,
          time: matchTime,
          scoreAtTime: `${score1 + 1} - ${score2}`,
        },
      ]);
      setScore1((prev) => prev + 1);
    });

    socket.on("add-goal-2", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        {
          team: 2,
          scorer: data.scorer,
          mention: data.mention,
          time: matchTime,
          scoreAtTime: `${score1} - ${score2 + 1}`,
        },
      ]);
      setScore2((prev) => prev + 1);
    });

    socket.on("remove-goal-1", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        {
          team: 1,
          scorer: data.scorer,
          removal: true,
          reason: data.reason,
          time: matchTime,
          scoreAtTime: `${score1 - 1} - ${score2}`,
        },
      ]);
      setScore1((prev) => prev - 1);
    });

    socket.on("remove-goal-2", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        {
          team: 2,
          scorer: data.scorer,
          removal: true,
          reason: data.reason,
          time: matchTime,
          scoreAtTime: `${score1} - ${score2 - 1}`,
        },
      ]);
      setScore2((prev) => prev - 1);
    });

    socket.on("add-yellow-card-1", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        { team: 1, player: data.player, card: "yellow", time: matchTime },
      ]);
    });

    socket.on("add-yellow-card-2", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        { team: 2, player: data.player, card: "yellow", time: matchTime },
      ]);
    });

    socket.on("add-red-card-1", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        { team: 1, player: data.player, card: "red", time: matchTime },
      ]);
    });

    socket.on("add-red-card-2", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        { team: 2, player: data.player, card: "red", time: matchTime },
      ]);
    });

    socket.on("switch-player-1", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        {
          team: 1,
          outPlayer: data.outPlayer,
          inPlayer: data.inPlayer,
          switch: true,
          time: matchTime,
        },
      ]);
    });

    socket.on("switch-player-2", (data) => {
      setGoals((prevGoals) => [
        ...prevGoals,
        {
          team: 2,
          outPlayer: data.outPlayer,
          inPlayer: data.inPlayer,
          switch: true,
          time: matchTime,
        },
      ]);
    });

    socket.on("start-match", (data) => {
      setLiveMatch(true);
      setIsHalftime(false);
    });

    socket.on("stop-match", (data) => {
      setLiveMatch(false);
      setScore1(0);
      setScore2(0);
      setGoals([]);
      setEnemyTeam("(No Name)");
      setMatchFinished(true);
      setMatchTime(0);
    });
    socket.on("change-team-2", (data) => {
      setEnemyTeam(data.enemyTeam);
    });

    socket.on("halftime", (data) => {
      setIsHalftime(data.isHalftime);
      if (data.isHalftime) setMatchTime(35);
    });

    socket.on("update-time", (data) => {
      setMatchTime(data.matchTime);
    });

    return () => {
      socket.off("add-goal-1");
      socket.off("add-goal-2");
      socket.off("remove-goal-1");
      socket.off("remove-goal-2");
      socket.off("start-match");
      socket.off("stop-match");
      socket.off("change-team-2");
      socket.off("add-yellow-card-1");
      socket.off("add-yellow-card-2");
      socket.off("add-red-card-1");
      socket.off("add-red-card-2");
      socket.off("switch-player-1");
      socket.off("switch-player-2");
      socket.off("halftime");
      socket.off("update-time");
    };
  }, [
    socket,
    score1,
    score2,
    liveMatch,
    enemyTeam,
    goals,
    matchTime,
    isHalftime,
  ]);

  return (
    <div className="flex flex-col gap-4 items-center justify-center mt-0.5">
      {liveMatch ? (
        <>
          <div className="w-full md:w-1/2 grid grid-cols-1 sticky top-[81px]">
            {/* Live Score */}
            <div className="grid grid-cols-3 w-full items-center justify-center text-2xl py-0.5 font-bold bg-gray-950 bg-opacity-50">
              <div className="flex flex-col items-center bg-opacity-20 ml-1">
                <span className="text-base md:text-lg">4ο ΓΕΛ Χαϊδαρίου</span>
              </div>
              <center>
                <div className="flex flex-col items-center">
                  <span className="text-xs text-red-500 animate-pulse">
                    LIVE
                  </span>
                  <span className="text-3xl text-center">
                    {score1} - {score2}
                  </span>
                  <span className="text-sm text-yellow-500">
                    {isHalftime ? "Ημίχρονο" : `${matchTime}'`}
                  </span>
                </div>
              </center>
              <div className="flex flex-col items-center bg-opacity-20 mr-1">
                <span className="text-base md:text-lg">{enemyTeam}</span>
              </div>
            </div>
            {/* Live Stream */}
            <center>
              <div className="w-full md:w-1/2 top-[170px] border border-yellow-300 border-opacity-50 z-10">
                <div
                  className="relative w-full"
                  style={{ paddingTop: "56.25%" }}
                >
                  <iframe
                    src="https://livepush.io/embed/emxmnRk0Luu57wc"
                    allowfullscreen="1"
                    frameborder="0"
                    className="absolute top-0 left-0 w-full h-full"
                  ></iframe>
                </div>
              </div>
            </center>

            {/* Live Score Updates */}
            <center className="w-full z-10 min-h-[calc(100vh-170px-56.25%-20px)] bg-gray-950 bg-opacity-50 backdrop-blur-md overflow-hidden flex flex-col justify-between pb-20">
              <Tabs
                defaultValue="updates"
                className="w-full h-full flex flex-col"
                onValueChange={(value) => {
                  setCurrentTab(value === "livechat");
                }}
              >
                <TabsList>
                  <TabsTrigger value="updates">Σύνοψη Αγώνα</TabsTrigger>
                  <TabsTrigger value="livechat">Ζωντανή Συνομιλία</TabsTrigger>
                </TabsList>
                <TabsContent value="livechat">
                  <div className="mt-10">
                    <span className=" p-2 bg-gray-800">
                      Live-Chat is still under development. ☹️
                    </span>
                  </div>
                  {/*  <ChatContainer axios={axios} socket={socket} /> */}
                </TabsContent>
                <TabsContent value="updates">
                  <div className="flex flex-col w-full md:w-1/2 items-center mt-4 text-lg font-semibold p-3 gap-4 rounded-lg overflow-hidden">
                    {goals.length > 0 ? (
                      goals.map((goal, index) =>
                        goal.card ? (
                          goal.team === 1 ? (
                            <div className="flex w-full items-center justify-start gap-2">
                              <span className="text-xs">{goal.time}'</span>
                              {goal.card === "yellow" ? (
                                <img src={YellowCardIcon} width={32} />
                              ) : (
                                <img src={RedCardIcon} width={32} />
                              )}
                              <span>{goal.player}</span>
                            </div>
                          ) : (
                            <div className="flex w-full items-center justify-end gap-2">
                              <span>({goal.player})</span>
                              {goal.card === "yellow" ? (
                                <img src={YellowCardIcon} width={32} />
                              ) : (
                                <img src={RedCardIcon} width={32} />
                              )}
                              <span className="text-xs">{goal.time}'</span>
                            </div>
                          )
                        ) : goal.switch ? (
                          goal.team === 1 ? (
                            <div className="flex w-full items-center justify-start gap-2">
                              <span className="text-xs">{goal.time}'</span>
                              <img src={SwitchIcon} width={32} />
                              <span className="flex flex-col items-start">
                                <span className="flex items-center gap-1">
                                  <span className="text-gray-400 text-xs">
                                    ΜΕΣΑ:
                                  </span>
                                  <span className="text-sm text-yellow-200">
                                    {goal.inPlayer}
                                  </span>
                                </span>
                                <span className="flex items-center gap-1">
                                  <span className="text-gray-400 text-xs">
                                    ΈΞΩ:
                                  </span>
                                  <span className="text-sm">
                                    {goal.outPlayer}
                                  </span>
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div className="flex w-full items-center justify-end gap-2">
                              <span className="flex flex-col items-start">
                                <span className="flex items-center gap-1">
                                  <span className="text-gray-400 text-xs">
                                    ΜΕΣΑ:
                                  </span>
                                  <span className="text-sm text-yellow-200">
                                    ({goal.inPlayer})
                                  </span>
                                </span>
                                <span className="flex items-center gap-1">
                                  <span className="text-gray-400 text-xs">
                                    ΈΞΩ:
                                  </span>
                                  <span className="text-sm">
                                    ({goal.outPlayer})
                                  </span>
                                </span>
                              </span>
                              <img src={SwitchIcon} width={32} />
                              <span className="text-xs">{goal.time}'</span>
                            </div>
                          )
                        ) : goal.team === 1 ? (
                          <div className="flex w-full items-center justify-start gap-2">
                            <span className="text-xs">{goal.time}'</span>
                            <span className="text-sm p-2 bg-gray-950 rounded-xl border border-yellow-200 border-opacity-50">
                              {goal.scoreAtTime}
                            </span>
                            <span>
                              {goal.removal ? "Ακύρωση Goal" : goal.scorer}
                            </span>
                            {goal.reason || goal.mention ? (
                              <span className="text-gray-300 text-sm">
                                ({goal.removal ? goal.reason : goal.mention})
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <div className="flex w-full items-center justify-end gap-2">
                            {goal.reason || goal.mention ? (
                              <span className="text-gray-300 text-sm">
                                ({goal.removal ? goal.reason : goal.mention})
                              </span>
                            ) : (
                              ""
                            )}
                            <span>
                              {goal.removal
                                ? "Ακύρωση Goal"
                                : `(${goal.scorer})`}
                            </span>
                            <span className="text-sm p-2 bg-gray-950 rounded-xl border border-yellow-200 border-opacity-50">
                              {goal.scoreAtTime}
                            </span>
                            <span className="text-xs">{goal.time}'</span>
                          </div>
                        )
                      )
                    ) : (
                      <span className="text-sm">
                        🚬Καμία ενημέρωση ακόμα...
                      </span>
                    )}
                  </div>
                </TabsContent>
              </Tabs>
            </center>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center mt-8">
            <img src={TetartoLogo} className="undraggable mb-4 w-24 md:w-36" />
            <div className="flex flex-col items-center">
              <h1 className="text-3xl md:text-4xl font-bold">
                <span className="magic">Ζωντανή Μετάδοση</span> - ({" "}
                <span className="text-red-500">Live</span> )
              </h1>
              <span className="text-lg md:text-2xl font-semibold flex items-center gap-1">
                Παρακολουθήστε ζωντανά τον αγώνα μας
              </span>
              <span className="text-sm">(Beta)</span>
            </div>
          </div>
          <div className="flex flex-col items-center bg-gray-200 bg-opacity-20 backdrop-blur p-4 shadow-md border border-yellow-200 rounded mt-4">
            <span className="text-lg font-semibold">
              {matchFinished
                ? "Ο αγώνας τελείωσε."
                : "Ο αγώνας δεν έχει ξεκινήσει ακόμα."}
            </span>
            <span className="text-sm text-yellow-500">
              {!matchFinished
                ? "Παρακαλώ περιμένετε..."
                : "❣️ Ευχαριστούμε που παρακολουθήσατε."}
            </span>
          </div>
          <Link to="/" className="text-yellow-500 hover:underline my-4">
            <Button variant="outline">🔙 Πίσω στην αρχική</Button>
          </Link>
        </>
      )}

      {/* Live Chat */}
      {/* <ChatContainer axios={axios} socket={socket} /> */}

      {/* Go Back Link */}
    </div>
  );
}
