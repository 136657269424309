import { React, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { Button } from "components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";

// Assets
import TetartoLogo from "assets/img/branding/TetartoLogo.png";
import Medal1Icon from "assets/img/icons/medal1.png";
import Medal2Icon from "assets/img/icons/medal2.png";
import Medal3Icon from "assets/img/icons/medal3.png";

export default function VoteRes({ axios }) {
  const { voteId } = useParams();

  const [team, setTeam] = useState({ label: voteId, votes: [] });

  useEffect(() => {
    axios.get(`votes/${voteId}`).then((res) => {
      setTeam(res.data);
    });
  }, []);

  return (
    <center className="my-10 text-white">
      <div className="w-full max-w-[1000px] flex flex-col gap-1 items-center px-4">
        <img src={TetartoLogo} className="undraggable mb-4 w-24 md:w-36" />
        <h1 className="text-3xl md:text-4xl font-bold">
          <span className="magic">Αποτελέσματα</span> - ( {team.label} )
        </h1>
        <span className="text-lg md:text-2xl font-semibold flex items-center gap-1">
          Votes: <span className="magic">{team.totalVotes}</span>
        </span>
        <p>
          Από κάτω μπορείς να δεις αναλυτικά τα αποτελέσματα του αγαπημένου
          παίκτη.
        </p>

        <Link to="/vote" className="text-yellow-500 hover:underline mt-4">
          <Button variant="outline">🔙 Πίσω στην επιλογή</Button>
        </Link>

        <Table variant="outline" className="my-4 border border-yellow-700 ">
          <TableCaption className="text-yellow-400">Total Votes.</TableCaption>
          <TableHeader className="bg-yellow-800">
            <TableRow>
              <TableHead className="border px-4 py-2 text-yellow-300">
                #
              </TableHead>
              <TableHead className="border px-4 py-2 text-yellow-300">
                Avatar
              </TableHead>
              <TableHead className="border px-4 py-2 text-yellow-300">
                Όνομα
              </TableHead>
              <TableHead className="border px-4 py-2 text-right text-yellow-300">
                Ψήφοι
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {team.votes.map((vote, i) => (
              <TableRow
                key={i}
                className={
                  vote.voted
                    ? "bg-blue-800"
                    : i % 2 === 0
                    ? "bg-yellow-800"
                    : "bg-yellow-700"
                }
              >
                <TableCell className="border px-4 py-2">
                  <div className="flex items-center justify-start">
                    {i < 3 ? (
                      <img
                        src={
                          i === 0
                            ? Medal1Icon
                            : i === 1
                            ? Medal2Icon
                            : Medal3Icon
                        }
                        width={27.5}
                      />
                    ) : (
                      ""
                    )}
                    <span>{i + 1}</span>
                  </div>
                </TableCell>
                <TableCell className="border px-4 py-2">
                  <Avatar className="w-10 h-10">
                    <AvatarImage src={vote.pfp} />
                    <AvatarFallback>{vote.name.slice(0, 2)}</AvatarFallback>
                  </Avatar>
                </TableCell>
                <TableCell className="border px-4 py-2">{vote.name}</TableCell>
                <TableCell className="border px-4 py-2 text-right font-bold">
                  {vote.votes}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </center>
  );
}
